<template>
  <div class="page-content d-flex align-items-center justify-content-center">
    <div class="row w-100 mx-0 auth-page">
      <div class="col-lg-8 mx-auto">
        <div class="card">
          <div class="row">
            <div class="col-md-5 pr-md-0 d-none d-md-block">
              <div class="auth-left-wrapper px-5 py-5">
                <div class="d-flex align-items-start mb-4">
                  <div class="mr-3">
                    <i class="ic-whatsapp" style="font-size: 22px"></i>
                  </div>
                  <div>
                    <h5 class="mb-2">Verified Whatsapp</h5>
                    <p class="text-muted">We help your business get on Whatsapp</p>
                  </div>
                </div>
                <div class="d-flex align-items-start mb-4">
                  <div class="mr-3">
                    <i class="ic-inbox" style="font-size: 22px"></i>
                  </div>
                  <div>
                    <h5 class="mb-2">Inbox for everyone</h5>
                    <p class="text-muted">24/7 support for you and your team, with local pressence in 7 countries</p>
                  </div>
                </div>
                <div class="d-flex align-items-start mb-4">
                  <div class="mr-3">
                    <i class="ic-channel" style="font-size: 22px"></i>
                  </div>
                  <div>
                    <h5 class="mb-2">Channels supported</h5>
                    <p class="">
                      <i class="ic-twitter mr-2"></i>
                      <i class="ic-messenger mr-2"></i>
                      <i class="ic-telegram mr-2"></i>
                      <i class="ic-whatsapp mr-2"></i>
                      <span class="text-muted">and more</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-7 pl-md-0">
              <div class="auth-form-wrapper px-4 py-5">
                <h4 class="mb-2">Welcome back!</h4>
                <h5 class="text-muted font-weight-normal mb-4">Log in to your account.</h5>
                <b-alert dismissible variant="danger" :show="alert_opt.show">{{ alert_opt.message }}</b-alert>
                <form class="forms-sample" @submit.prevent="doLogin">
                  <div class="form-group">
                    <label for="input-email">Email address</label>
                    <input type="email" class="form-control" id="input-email" placeholder="Email" v-model="email" name="email" required>
                  </div>
                  <div class="form-group">
                    <label for="input-password">Password</label>
                    <input type="password" class="form-control" id="input-password" placeholder="Password" v-model="password" name="password" required>
                  </div>
                  <div class="d-flex justify-content-between mt-3">
                    <button type="submit" class="btn btn-primary mr-2 mb-2 mb-md-0 text-white">Login</button>
                    <a @click="(e) => { e.preventDefault(); $router.push('/auth/reset-password'); }" href="/auth/reset-password" class="d-block text-muted">Forgot password?</a>
                  </div>
                  <a @click="(e) => { e.preventDefault(); $router.push('/auth/register'); }" href="/auth/register" class="d-block mt-3 text-muted">Not a user? Sign up</a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  metaInfo: {
    title: 'Login',
  },
  data() {
    return {
      email: '',
      password: '',
      alert_opt: {
        show: false,
        message: '',
      },
    };
  },
  methods: {
    async doLogin() {
      const loader = this.$loading.show();
      const data = {
        email: this.email,
        password: this.password,
      };
      this.alert_opt.show = false;
      await this.$store.dispatch('auth/doLogin', data)
        .then(async (res) => {
          if (!res.error) {
            if (this.$route.query.redirect_to) {
              window.location = this.$route.query.redirect_to;
              return;
            }
            this.$router.push('/workspaces');
            this.$store.dispatch('ui/clearAlert');
          } else {
            this.alert_opt = {
              show: true,
              message: res.error.message,
            };
          }
        })
        .catch(async () => {
          this.alert_opt = {
            show: true,
            message: this.$t('general.error.general'),
          };
          this.showAlert();
        });

      loader.hide();
    },
  },
};
</script>
